$MAIN_COLOR: #ffbc62;
$MAIN_COLOR_OPACITY: rgba(255, 188, 98, 0.5);
$SECONDARY_COLOR: #173e7d;
$SECONDARY_COLOR_OPACITY: #cfcfea;
$TERTIARY_COLOR: #bfd730;
$MENU_ITEM_ACTIVE: #ffbc62;
$MENU_BORDER: #03f7e0;
$BACKGROUND_COLOR: #f7f7f7;
$ERROR_RED: #f56262;
// $BLACK_TEXT_COLOR: #242529;
$BLACK_TEXT_COLOR: #173e7d;
$GRAY_TEXT_COLOR: #848a8f;
$SECTION_COLOR: #243138;
$MENU_ICON_ACTIVE: #ffbc62;
$MOBILE_MENU_OPEN_ICON_COLOR: $MAIN_COLOR;
$MENU_CLOSE_ICON_COLOR: $MAIN_COLOR;
$MENU_OPEN_ICON_COLOR: $MAIN_COLOR;
$GRAY: #ebecf0;
$GRAY_BORDER: 1px solid #d6d8db;
$DISABLED_GRAY: #dbdbdb;
$MENU_BACKGROUND: #173e7d;
$MENU_LOGOUT_TEXT_COLOR: #c3c3c3;
$MENU_ITEM_HOVER_BACKGROUND: rgba(255, 188, 98, 0.1);
$MENU_ITEM_TEXT_WEIGHT: bold;
$MENU_LINE_COLOR: transparent;

$MENU_ICON_ACTIVE: #ffbc62;
$MENU_TEXT_ACTIVE: #ffbc62;
$COMPONENT_OPTION_BUTTON_COLOR: #ffbc62;

$CARDS_SCREEN_BG: #173e7d;
$TRANSACTIONS_ITEM_BG: rgb(254, 233, 204);
$TRANSACTION_ITEM_ICON_COLOR: #ffbc62;
$NOTIFICATION_ITEM_ICON_COLOR: #ffbc62;

$WALLET_AVAILABLE: #ffbc62;
$WALLET_TODAY: #173e7d;
$CARD_ITEM_BG: #636382;

$BUTTON_RADIUS: 12px;

:export {
  MAIN_COLOR: $MAIN_COLOR;
  MAIN_COLOR_OPACITY: $MAIN_COLOR_OPACITY;
  SECONDARY_COLOR: $SECONDARY_COLOR;
  TERTIARY_COLOR: $TERTIARY_COLOR;
  MENU_ITEM_ACTIVE: $MENU_ITEM_ACTIVE;
  MENU_BORDER: $MENU_BORDER;
  BACKGROUND_COLOR: $BACKGROUND_COLOR;
  ERROR_RED: $ERROR_RED;
  SECONDARY_COLOR_OPACITY: $SECONDARY_COLOR_OPACITY;
  BLACK_TEXT_COLOR: $BLACK_TEXT_COLOR;
  GRAY_TEXT_COLOR: $GRAY_TEXT_COLOR;
  SECTION_COLOR: $SECTION_COLOR;
  MENU_ICON_ACTIVE: $MENU_ICON_ACTIVE;
  MENU_CLOSE_ICON_COLOR: $MENU_CLOSE_ICON_COLOR;
  MENU_OPEN_ICON_COLOR: $MENU_OPEN_ICON_COLOR;
  DISABLED_GRAY: $DISABLED_GRAY;
  MOBILE_MENU_OPEN_ICON_COLOR: $MOBILE_MENU_OPEN_ICON_COLOR;
  GRAY_BORDER: $GRAY_BORDER;
  GRAY: $GRAY;
  MENU_BACKGROUND: $MENU_BACKGROUND;
  MENU_LOGOUT_TEXT_COLOR: $MENU_LOGOUT_TEXT_COLOR;
  MENU_ITEM_HOVER_BACKGROUND: $MENU_ITEM_HOVER_BACKGROUND;
  MENU_ITEM_TEXT_WEIGHT: $MENU_ITEM_TEXT_WEIGHT;
  MENU_LINE_COLOR: $MENU_LINE_COLOR;

  MENU_ICON_ACTIVE: $MENU_ICON_ACTIVE;
  MENU_TEXT_ACTIVE: $MENU_TEXT_ACTIVE;
  COMPONENT_OPTION_BUTTON_COLOR: $COMPONENT_OPTION_BUTTON_COLOR;

  CARDS_SCREEN_BG: $CARDS_SCREEN_BG;
  TRANSACTIONS_ITEM_BG: $TRANSACTIONS_ITEM_BG;
  TRANSACTION_ITEM_ICON_COLOR: $TRANSACTION_ITEM_ICON_COLOR;
  NOTIFICATION_ITEM_ICON_COLOR: $NOTIFICATION_ITEM_ICON_COLOR;
  CARD_ITEM_BG: $CARD_ITEM_BG;

  WALLET_AVAILABLE: $WALLET_AVAILABLE;
  WALLET_TODAY: $WALLET_TODAY;

  BUTTON_RADIUS: $BUTTON_RADIUS;
}

body {
  --MAIN_COLOR: #{$MAIN_COLOR};
  --MAIN_COLOR_OPACITY: #{$MAIN_COLOR_OPACITY};
  --SECONDARY_COLOR: #{$SECONDARY_COLOR};
  --TERTIARY_COLOR: #{$TERTIARY_COLOR};
  --MENU_ITEM_ACTIVE: #{$MENU_ITEM_ACTIVE};
  --MENU_BORDER: #{$MENU_BORDER};
  --BACKGROUND_COLOR: #{$BACKGROUND_COLOR};
  --ERROR_RED: #{$ERROR_RED};
  --SECONDARY_COLOR_OPACITY: #{$SECONDARY_COLOR_OPACITY};
  --BLACK_TEXT_COLOR: #{$BLACK_TEXT_COLOR};
  --GRAY_TEXT_COLOR: #{$GRAY_TEXT_COLOR};
  --SECTION_COLOR: #{$SECTION_COLOR};
  --MENU_ICON_ACTIVE: #{$MENU_ICON_ACTIVE};
  --MENU_CLOSE_ICON_COLOR: #{$MENU_CLOSE_ICON_COLOR};
  --MENU_OPEN_ICON_COLOR: #{$MENU_OPEN_ICON_COLOR};
  --DISABLED_GRAY: #{$DISABLED_GRAY};
  --MOBILE_MENU_OPEN_ICON_COLOR: #{$MOBILE_MENU_OPEN_ICON_COLOR};
  --GRAY_BORDER: #{$GRAY_BORDER};
  --GRAY: #{$GRAY};
  --MENU_BACKGROUND: #{$MENU_BACKGROUND};
  --MENU_LOGOUT_TEXT_COLOR: #{$MENU_LOGOUT_TEXT_COLOR};
  --MENU_ITEM_HOVER_BACKGROUND: #{$MENU_ITEM_HOVER_BACKGROUND};
  --MENU_ITEM_TEXT_WEIGHT: #{$MENU_ITEM_TEXT_WEIGHT};
  --MENU_LINE_COLOR: #{$MENU_LINE_COLOR};

  --MENU_ICON_ACTIVE: #{$MENU_ICON_ACTIVE};
  --MENU_TEXT_ACTIVE: #{$MENU_TEXT_ACTIVE};
  --COMPONENT_OPTION_BUTTON_COLOR: #{$COMPONENT_OPTION_BUTTON_COLOR};

  --CARDS_SCREEN_BG: #{$CARDS_SCREEN_BG};
  --TRANSACTIONS_ITEM_BG: #{$TRANSACTIONS_ITEM_BG};
  --TRANSACTION_ITEM_ICON_COLOR: #{$TRANSACTION_ITEM_ICON_COLOR};
  --NOTIFICATION_ITEM_ICON_COLOR: #{$NOTIFICATION_ITEM_ICON_COLOR};
  --CARD_ITEM_BG: #{$CARD_ITEM_BG};

  --WALLET_AVAILABLE: #{$WALLET_AVAILABLE};
  --WALLET_TODAY: #{$WALLET_TODAY};

  --BUTTON_RADIUS: #{$BUTTON_RADIUS};
}
